/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Phone from 'img/phone.inline.svg'
import Facebook from 'img/facebook.inline.svg'
import Linkedin from 'img/linkedin.inline.svg'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface ContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Contact
}

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 1.5rem;
    /* max-width: 400px; */
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border: none !important;
    outline: none;
    background-color: ${({ theme }) => theme.color.grey};
    border-style: solid;
    border-width: 1px;
    min-height: 50px;
    padding: 0 15px;

    &::placeholder {
      opacity: 0.5;
    }
  }

  & .form-duuf-textarea {
    padding: 10px 15px !important;
    min-height: 170px;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const Title = styled.div`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

const Content = styled.div`
  font-size: 18px;
  line-height: 24px;

  & svg {
    margin-right: 10px;
  }

  & a {
    &:hover {
      text-decoration: underline;
    }
  }
`

const MailLink = styled.div`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.primary};

  & a {
    &:hover {
      text-decoration: underline;
    }
  }
`

const Contact: React.FC<ContactProps> = ({ fields }) => {
  const { branches } = useStaticQuery<GatsbyTypes.contactQueryQuery>(graphql`
    query contactQuery {
      branches: allWpVestiging {
        nodes {
          title
          acfFields {
            address
            zipcode
            phone {
              title
              url
            }
            postbus
          }
        }
      }
    }
  `)

  return (
    <section>
      <div className="row justify-content-between">
        <div className="col-lg-5 pb-lg-0 pb-4">
          {branches.nodes.map((node, index) => (
            <div className="pb-lg-5 pb-4" key={index}>
              <Title className="pb-lg-3 pb-2">
                <div>Vestiging</div>
                <div>{node.title}</div>
              </Title>
              <Content>
                <div>{node.acfFields?.address}</div>
                <div>{node.acfFields?.zipcode}</div>
                <div>
                  <a
                    className="d-flex align-items-center"
                    href={node.acfFields?.phone?.url || '/'}
                  >
                    <Phone />
                    {node.acfFields?.phone?.title}
                  </a>
                </div>
                {node?.acfFields?.postbus !== '' && (
                  <ParseContent
                    className="pt-3"
                    content={node.acfFields?.postbus || ''}
                  />
                )}
              </Content>
            </div>
          ))}
          <div>
            <MailLink className="pb-3">
              <a href={fields.mail?.url || '/'}>{fields.mail?.title}</a>
            </MailLink>
            <div>
              <a
                target="__blank"
                className="me-3"
                href={fields.facebook?.url || '/'}
              >
                <Facebook />
              </a>
              <a target="__blank" href={fields.linkedin?.url || '/'}>
                <Linkedin />
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <StyledFormDuuf id={1} generate={false}>
            <div className="row">
              <div className="col-6">
                <FormDuufGroup showIds={[4]} />
              </div>
              <div className="col-6">
                <FormDuufGroup showIds={[8]} />
              </div>
              <div className="col-12">
                <FormDuufGroup hideIds={[4, 8]} />
              </div>
            </div>
            <FormDuufSubmit />
          </StyledFormDuuf>
        </div>
      </div>
    </section>
  )
}

export default Contact
